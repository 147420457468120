import { TGoogleAnalyticsV4GTMEvent, TGoogleAnalyticsV4GTMOptions } from './types';

import { AnalyticService } from '@frontstoreRwd/modules/analytics/classes/service';
import { TServiceName } from '../../types';
import eventsHandlersMapper from './events';

export class GoogleAnalyticV4GTMService extends AnalyticService<GoogleAnalyticV4GTMService> {
    private _options: TGoogleAnalyticsV4GTMOptions;
    private _layer: unknown[] = [];

    protected _events: TGoogleAnalyticsV4GTMEvent = eventsHandlersMapper;

    constructor(name: TServiceName, options: TGoogleAnalyticsV4GTMOptions) {
        super(name);
        this._options = options;
    }

    public async setup(): Promise<void> {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this._layer = window[this._options.dataLayerName] || [];
        this._isSetuped = true;
    }

    public getLayer(): unknown[] {
        return this._layer;
    }
}
