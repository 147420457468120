//@ts-nocheck
import { simpleSanitizeHTML } from '@core/dom_utilities/sanitize';

export const liTemplate = ({
    productId = '',
    categoryName = '',
    producerName = '',
    imgFileName = '__overlay.jpg',
    productName = '',
    productVariantName = '',
    quantity = '',
    price = '',
    removeUrl = '',
    productUrl = '',
    removePhrase = ''
}) => `
    <li data-product-id="${productId}" data-category="${simpleSanitizeHTML(categoryName)}" data-producer="${simpleSanitizeHTML(producerName)}">
        <img src="/environment/cache/images/75_75_productGfx_${imgFileName}" alt="${simpleSanitizeHTML(productName)}">

        <a class="product-name" href="${productUrl}" title="${simpleSanitizeHTML(productName)}">
            ${simpleSanitizeHTML(productName)}
            ${productVariantName ? `<span class="product-variant">${simpleSanitizeHTML(productVariantName)}</span>` : ''}
        </a>

        <span class="product-info">
            <span class="product-amount">${quantity}</span> x <span class="product-price">${price}</span>
        </span>
    
        <span class="remove-product"><a href="${removeUrl}">${removePhrase}</a></span>
    </li>
`;
