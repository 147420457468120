export function stringToNumber(value: string, decSep: string, thousSep: string): number {
    if (!decSep && Shop.values.decimalSep) {
        decSep = Shop.values.decimalSep || ',';
    }

    if (!thousSep && Shop.values.thousandSep) {
        thousSep = Shop.values.thousandSep || ' ';
    }

    if (!thousSep || thousSep == ' ' || thousSep === '\u00a0') {
        thousSep = 's';
    }

    const r = new RegExp('\\' + decSep, 'g');
    const r2 = new RegExp('\\' + thousSep, 'g');
    const r3 = new RegExp('[^\\d' + decSep + '\\' + thousSep + ']', 'g');

    return parseFloat(value.replace(r3, '').replace(r2, '').replace(r, '.'));
}
