import { GOOGLE_ANALATYICS_V4_GTAG, GOOGLE_ANALATYICS_V4_GTM_CLIENT } from '../services_names';
import { GTM_TRACKING_TYPE, NATIVE_TRACKING_TYPE } from './constants';

import { AnalyticManager } from '../../classes/manager';
import { GoogleAnalyticV4GTMService } from './gtm';
import { GoogleAnalyticV4GtagService } from './gtag';
import { IAnalyticService } from '../../classes/service/types';
import { TGoogleAnalyticsV4Options } from '../../types';

export class GoogleAnalyticsV4Initializer {
    private _analyticsManager: AnalyticManager;
    private _options: TGoogleAnalyticsV4Options;

    private _serviceTypes = {
        [GTM_TRACKING_TYPE]: {
            create(): IAnalyticService {
                return new GoogleAnalyticV4GTMService(GOOGLE_ANALATYICS_V4_GTM_CLIENT, {
                    dataLayerName: 'dataLayer'
                });
            }
        },

        [NATIVE_TRACKING_TYPE]: {
            create(options: TGoogleAnalyticsV4Options): IAnalyticService {
                return new GoogleAnalyticV4GtagService(GOOGLE_ANALATYICS_V4_GTAG, {
                    trackingId: [options.trackingId]
                });
            }
        }
    };

    constructor(analyticManager: AnalyticManager, options: TGoogleAnalyticsV4Options) {
        this._analyticsManager = analyticManager;
        this._options = options;
    }

    public async init(): Promise<void> {
        const isClientGoogleAnalyticsV4Active = this._options && this._options.isActive;

        if (isClientGoogleAnalyticsV4Active) {
            const service = this._serviceTypes[this._options.isGtm].create(this._options);
            await this._analyticsManager.register(service);
        }
    }
}
