import { ANALYTICS_CONFIG_ELEMENT_ID } from './constants';
import { AnalyticManager } from './classes/manager';
import { FacebookBusinessExtensionInitializer } from './services/facebook_business_extension/facebook_business_extension_initializer';
import Features from '@core/tools/features';
import { GoogleAnalyticsV4Initializer } from './services/google_analytics_v4/initializer';
import { TAnalyticsOptions } from './types';
import customerPrivacy from '@frontstoreRwd/modules/customer_privacy/index';
import { getJSONFromScriptTag } from '@core/dom_utilities/get';

export class AnalyticsInitializer {
    private _analyticsOptions: TAnalyticsOptions | undefined;
    private _analyticsManager: AnalyticManager;

    constructor() {
        this._analyticsOptions = getJSONFromScriptTag(ANALYTICS_CONFIG_ELEMENT_ID) as TAnalyticsOptions;
        this._analyticsManager = new AnalyticManager();
    }

    public async init(): Promise<void> {
        customerPrivacy.onAnalyticsConsentGranted(() => {
            if (!this._analyticsOptions) {
                return;
            }

            if (Features.isEnabled('google_analytics_v4')) {
                new GoogleAnalyticsV4Initializer(this._analyticsManager, this._analyticsOptions.clientGoogleAnalyticsV4).init();
            }

            if (Features.isEnabled('facebook_business_extension')) {
                new FacebookBusinessExtensionInitializer(this._analyticsManager, this._analyticsOptions.facebookBusinessExtension).init();
            }
        });
    }
}
