import {
    ADD_PAYMENT_INFO,
    ADD_TO_CART,
    ADD_TO_WISHLIST,
    INITIATE_CHECKOUT,
    PURCHASE,
    SEARCH,
    VIEW_CONTENT
} from './facebook_business_extension_event_names';
import {
    CART_DISPLAYED,
    CHECKOUT_OPTIONS_SUBMITED,
    PRODUCTS_DISPLAYED,
    PRODUCT_ADDED_TO_CART,
    PRODUCT_DISPLAYED,
    PURCHASED
} from '@frontstoreRwd/events/events_names';

import { ADDED_TO_WISHLIST } from '@frontstoreRwd/modules/add_to_wishlist/add_to_wishlist_events';
import { AnalyticProduct } from '../../classes/product';
import { CONTENT_TYPES } from './facebook_business_extension_constants';
import { FacebookBusinessExtensionService } from './facebook_business_extension';
import { TFacebookBusinessExtensionEvent } from './facebook_business_extension_types';
import { stringToNumber } from '@core/utilities/currency/string_to_number';

const eventsHandlersMapper: TFacebookBusinessExtensionEvent = {
    [PRODUCT_ADDED_TO_CART]: (service: FacebookBusinessExtensionService, analyticProduct: unknown): void => {
        if (analyticProduct instanceof AnalyticProduct) {
            service.fbq(ADD_TO_CART, {
                content_ids: [analyticProduct.id],
                content_type: 'product',
                //@ts-ignore
                value: stringToNumber(analyticProduct.price),
                currency: analyticProduct.currency
            });
        }
    },

    [PRODUCT_DISPLAYED]: (service: FacebookBusinessExtensionService, analyticProduct: unknown): void => {
        if (analyticProduct instanceof AnalyticProduct) {
            service.fbq(VIEW_CONTENT, {
                //@ts-ignore
                value: stringToNumber(analyticProduct.price),
                currency: analyticProduct.currency,
                content_ids: [analyticProduct.id],
                content_category: analyticProduct.category,
                content_type: 'product'
            });
        }
    },

    [PRODUCTS_DISPLAYED]: (service: FacebookBusinessExtensionService, params: unknown): void => {
        if (typeof params === 'object') {
            const analyticProductsList = params as {
                products: AnalyticProduct[];
                currency: string;
            };

            const isSearchList = window.location.href.match(/\/searchquery\//);

            const fbeValue = analyticProductsList.products.reduce((prevValue, product) => {
                //@ts-ignore
                return prevValue + stringToNumber(product.price);
            }, 0);

            const fbeIds = analyticProductsList.products.map((product) => product.id);

            const contentCategory = CONTENT_TYPES[document.body.className.split(' ')[0]];

            if (isSearchList) {
                const searchString = decodeURIComponent(location.pathname.split('/')[3].replace(/\+/g, ' '));

                service.fbq(SEARCH, {
                    value: fbeValue,
                    currency: analyticProductsList.currency,
                    content_ids: fbeIds,
                    content_category: contentCategory,
                    content_type: 'product',
                    search_string: searchString
                });
            } else {
                service.fbq(VIEW_CONTENT, {
                    value: fbeValue,
                    currency: analyticProductsList.currency,
                    content_ids: fbeIds,
                    content_category: contentCategory,
                    content_type: 'product'
                });
            }
        }
    },

    [CART_DISPLAYED]: (service: FacebookBusinessExtensionService, params: unknown): void => {
        if (typeof params === 'object') {
            const analyticProductsList = params as {
                products: AnalyticProduct[];
                currency: string;
                coupon: string | undefined;
            };

            const fbeValue = analyticProductsList.products.reduce((prevValue, product) => {
                //@ts-ignore
                return prevValue + stringToNumber(product.price);
            }, 0);

            const fbeIds = analyticProductsList.products.map((product) => product.id);

            const contentCategory = CONTENT_TYPES[document.body.className.split(' ')[0]];

            service.fbq(INITIATE_CHECKOUT, {
                value: fbeValue,
                currency: analyticProductsList.currency,
                content_ids: fbeIds,
                content_category: contentCategory,
                content_type: 'product',
                num_items: analyticProductsList.products.length
            });
        }
    },

    [CHECKOUT_OPTIONS_SUBMITED]: (service: FacebookBusinessExtensionService, params: unknown): void => {
        if (typeof params === 'object') {
            const checkoutParams = params as {
                paymentName: string;
                currency: string;
            };

            const $sum = document.querySelector('#cart-options .sum .value, #box_basketshipping .sum .value') as HTMLElement;

            const value = stringToNumber($sum.innerText);

            service.fbq(ADD_PAYMENT_INFO, {
                value,
                currency: checkoutParams.currency,
                contents: checkoutParams.paymentName
            });
        }
    },

    [ADDED_TO_WISHLIST]: (service: FacebookBusinessExtensionService, analyticProduct: unknown): void => {
        if (analyticProduct instanceof AnalyticProduct) {
            service.fbq(ADD_TO_WISHLIST, {
                content_ids: [analyticProduct.id],
                content_type: 'product',
                content_category: analyticProduct.category,
                content_name: 'Products | Product',
                value: stringToNumber(analyticProduct.price),
                currency: analyticProduct.currency
            });
        }
    },

    [PURCHASED]: (service: FacebookBusinessExtensionService, params: unknown): void => {
        if (typeof params === 'object') {
            const purchaseParams = params as {
                orderSummary: {
                    id: string;
                    revenue: string;
                    shipping: string;
                    tax: string;
                };
                products: AnalyticProduct[];
                currency: string;
            };

            const fbeIds = purchaseParams.products.map((product) => {
                const [licenseId, productId] = product.id.split('_');

                return productId || licenseId;
            });

            const contentCategory = CONTENT_TYPES[document.body.className.split(' ')[0]];
            const [licenseId, orderId] = purchaseParams.orderSummary.id.split('_');

            service.fbq(
                PURCHASE,
                {
                    value: purchaseParams.orderSummary.revenue,
                    currency: purchaseParams.currency,
                    content_ids: fbeIds,
                    content_category: contentCategory,
                    content_type: 'product',
                    num_items: purchaseParams.products.length
                },
                {
                    eventID: orderId || licenseId
                }
            );
        }
    }
};

export default eventsHandlersMapper;
