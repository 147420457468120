import { $emit, $on } from '@core/tools/event_bus';
import {
    BEGAN_CHECKOUT,
    CART_DISPLAYED,
    CHECKOUT_OPTIONS_SUBMITED,
    PRODUCT_ADDED_TO_CART,
    PRODUCT_REMOVED_FROM_CART,
    PURCHASED
} from '@frontstoreRwd/events/events_names';

import { AnalyticProduct } from '@frontstoreRwd/modules/analytics/classes/product';
import Features from '@core/tools/features';
import customerPrivacy from '@frontstoreRwd/modules/customer_privacy/index';
import { updateDeliveryTimes } from './functions';

(function (undefined) {
    Shop.BasketHandler.include({
        options: {
            shipping_id: 0,
            payment_id: 0,
            country_id: 0,
            shipping_row: null,
            force_rows: false,
            step: 0,
            ordersumfield: null,
            currency: {
                dec_point: ',',
                thousands_sep: ' ',
                prefix: '',
                postfix: ''
            },
            containers: {
                step1: false,
                step2: false,
                step3: false
            },
            pre: {
                step1: false,
                step2: false,
                step3: false
            },
            post: {
                step1: false,
                step2: false,
                step3: false
            },
            selectors: {
                deliveryrow: '',
                paymentrow: '',
                deliverychangelink: '',
                paymentchangelink: '',
                paymentheadlabel: '',
                paymentrlabel: '',
                paymentradios: '',
                deliveryradios: '',
                deliveryheadlabel: '',
                deliveryheadvalue: '',
                deliverytrlabel: '',
                deliverytrvalue: '',
                deliverylabel: '',
                trradio: '',
                countrytr: '',
                trcountryselect: '',
                trdifferentaddress: '',
                differentaddress: '',
                differentaddress_parent: '',
                personaladdress: '',
                companyaddress: '',
                formcompanyname: '',
                formtaxid: '',
                formcompanyname2: '',
                formtaxid2: '',
                countryselect: '',
                addresstyperadios: '',
                addressselectsubmit: '',
                addressselect: '',
                addressfieldscontainer: '',
                recalc: '',
                recalcbtn: ''
            },
            currency_map: '',
            getaddressurl: '',
            pocztaPolskaBasketHandler: null
        },

        initialize: function (options) {
            this.constructor.setOptions(options);

            if ($('[data-pp-pick-up-points]').length) {
                this.pocztaPolskaBasketHandler = new Shop.PocztaPolskaBasketHandler();
            }

            if (Shop.exist(Shop.values.CurrencyMap)) {
                this.options.currency_map = Shop.values.CurrencyMap;
                this.parse_currency();
            }

            if (Shop.exist(Shop.values.ShippingValue)) {
                this.options.shippingValue = Shop.values.ShippingValue;
            }

            if (Shop.values.SumNoShipping >= 0) {
                this.options.sumNoShipping = Shop.values.SumNoShipping;
            }

            if (Shop.exist(Shop.values.Shipping2Payment)) {
                this.options.shipping2Payment = Shop.values.Shipping2Payment;
            }

            if (Shop.exist(Shop.values.Country2Shipping)) {
                this.options.country2Shipping = Shop.values.Country2Shipping;
            }

            if (Shop.exist(Shop.values.PaymentAdditional)) {
                this.options.paymentAdditional = Shop.values.PaymentAdditional;
            }

            if (Shop.exist(Shop.values.ShippingHidden)) {
                this.options.shippingHidden = Shop.values.ShippingHidden;
            }

            if (Shop.exist(Shop.values.reverseTable)) {
                this.options.reverseTable = Shop.values.reverseTable;
            }

            this.options.deliveryTimes = Shop.values.deliveryTimes;

            if (
                (this.options.step > 0 || this.options.step === 'shipping' || this.options.step === 'done') &&
                typeof this['step' + this.options.step] == 'function'
            ) {
                var s = 'step' + this.options.step;

                if (typeof this.options.pre[s] == 'function') {
                    this.options.pre[s](this);
                }

                this[s](this);

                if (typeof this.options.post[s] == 'function') {
                    this.options.post[s](this);
                }
            }
        },

        parse_currency: function () {
            var cmap = this.options.currency_map;
            this.options.currency.prefix = cmap.replace(/^(.*)1.*/, '$1');
            this.options.currency.postfix = cmap.replace(/.*6(.*)$/, '$1');
            this.options.currency.dec_point = cmap.replace(/.*4(.*)5.*/, '$1');
            this.options.currency.thousands_sep = cmap.replace(/.*1(.*)2.*/, '$1');
        },

        format_currency: function (x) {
            return (
                this.options.currency.prefix +
                x.numberFormat(2, this.options.currency.dec_point, this.options.currency.thousands_sep) +
                this.options.currency.postfix
            );
        },

        checkSeparators: function (sum) {
            var separators;

            if (!sum) {
                return false;
            }

            separators = sum.match(/[,.\s]/g);
            if (separators.length >= 2) {
                if (separators.indexOf(Shop.values.decimalSep) < 0 || separators.indexOf(Shop.values.thousandSep) < 0) {
                    Shop.values.decimalSep = separators[1];
                    Shop.values.thousandSep = separators[0];
                }
            } else {
                if (separators.indexOf(Shop.values.decimalSep) < 0) {
                    Shop.values.decimalSep = separators[0];
                }
            }
        },

        calculate: function () {
            var self = this,
                td = this.options.ordersumfield,
                shippingPrice = this.options.shippingValue['s' + this.options.shipping_id],
                orderPrice,
                discount,
                separators,
                totalVal;

            this.checkSeparators($('.recount .sum .color').html());
            orderPrice = parseFloat(this.parent.toPrimaryValue($('.recount .sum .color').html()));

            if (this.options.shippingValue['cs' + this.options.shipping_id]) {
                shippingPrice = this.options.shippingValue['cs' + this.options.shipping_id][this.options.country_id];
            }

            if (this.options.shippingValue['cs' + this.options.shipping_id] instanceof Array && shippingPrice === undefined) {
                shippingPrice = this.options.shippingValue['s' + this.options.shipping_id];
            }

            if (shippingPrice === undefined) {
                shippingPrice = 0;
            }

            totalVal = shippingPrice + orderPrice;

            if ($('.promo-container .promo .value').html()) {
                discount = $('.promo-container .promo .value')
                    .html()
                    .replace(/[^\d,.]/g, '');
                discount = this.parent.toPrimaryValue(discount);
            }

            if (discount && !isNaN(discount)) {
                totalVal -= discount;
            }

            totalVal = this.format_currency(totalVal);
            $('.summary-container .sum .value').html(totalVal);
            $('input[name="shipping_id"]').each(function () {
                var id;

                id = parseInt($(this).val(), 10);
                if (self.options.shippingValue['cs' + id] && self.options.shippingValue['cs' + id][self.options.country_id]) {
                    $(this)
                        .closest('span.name')
                        .next()
                        .html(self.format_currency(self.options.shippingValue['cs' + id][self.options.country_id]));
                }
            });

            if (
                Shop.exist(td) &&
                Shop.exist(this.options.shippingValue) &&
                Shop.exist(this.options.sumNoShipping) >= 0 &&
                this.options.shipping_id > 0
            ) {
                var adds = this.shipping_additional(this.options.shipping_id, this.options.payment_id);
                var sv = this.options.shippingValue['s' + this.options.shipping_id];

                if (
                    this.options.shippingValue['cs' + this.options.shipping_id] &&
                    this.options.shippingValue['cs' + this.options.shipping_id][this.options.country_id]
                ) {
                    sv = this.options.shippingValue['cs' + this.options.shipping_id][this.options.country_id];
                }

                if (adds != null) {
                    sv += adds['v'];
                }

                var s = this.options.sumNoShipping + sv;
                $(td).html(this.format_currency(s));

                $('.sumhref').each(function () {
                    var url = $(this).attr('data-sum-href').replace('{sum}', s);
                    if (url) {
                        $(this).attr('href', url);
                    }
                });

                if (Shop.exist(this.options.shipping_row)) {
                    this.options.shipping_row.find('.value').html(this.format_currency(shippingPrice));
                    this.options.containers.step1.find(this.options.selectors.deliveryheadvalue).html(this.format_currency(sv));
                }
            }

            if (this.options.shippingHidden) {
                // very good
                var x;
                var y;
                var sId;
                var min;
                var minJoined;
                var currentMin;
                var count;

                for (x in this.options.shippingValue) {
                    if (this.options.shippingValue.hasOwnProperty(x)) {
                        if (
                            this.options.shippingValue[x] &&
                            typeof this.options.shippingValue[x] === 'object' &&
                            this.options.shippingValue[x][this.options.country_id] !== undefined
                        ) {
                            sId = x.replace('c', '');

                            if (min === undefined) {
                                min = this.options.shippingValue[x][this.options.country_id];

                                if (this.options.paymentAdditional && this.options.paymentAdditional[sId]) {
                                    count = 0;
                                    for (y in this.options.paymentAdditional[sId]) {
                                        if (this.options.paymentAdditional[sId].hasOwnProperty(y)) {
                                            if (!minJoined) {
                                                minJoined = min + this.options.paymentAdditional[sId][y].v;
                                            } else {
                                                minJoined = Math.min(minJoined, min + this.options.paymentAdditional[sId][y].v);
                                            }

                                            count += 1;
                                        }
                                    }

                                    if (this.options.shipping2Payment[sId].length === count) {
                                        min = minJoined;
                                    }

                                    minJoined = null;
                                }
                            } else {
                                currentMin = this.options.shippingValue[x][this.options.country_id];

                                if (this.options.paymentAdditional && this.options.paymentAdditional[sId]) {
                                    count = 0;
                                    for (y in this.options.paymentAdditional[sId]) {
                                        if (this.options.paymentAdditional[sId].hasOwnProperty(y)) {
                                            if (!minJoined) {
                                                minJoined = currentMin + this.options.paymentAdditional[sId][y].v;
                                            } else {
                                                minJoined = Math.min(minJoined, currentMin + this.options.paymentAdditional[sId][y].v);
                                            }

                                            count += 1;
                                        }
                                    }

                                    if (this.options.shipping2Payment[sId].length === count) {
                                        currentMin = minJoined;
                                    }

                                    minJoined = null;
                                }

                                min = Math.min(currentMin, min);
                            }
                        }
                    }
                }

                if (Shop.exist(this.options.shipping_row)) {
                    this.options.containers.step1.find(this.options.selectors.deliveryheadvalue).html(this.format_currency(min));
                    if (this.options.sumNoShipping > 0) {
                        totalVal = min + this.options.sumNoShipping;
                    } else {
                        totalVal = min + orderPrice;

                        if (discount && !isNaN(discount)) {
                            totalVal -= discount;
                        }
                    }

                    totalVal = this.format_currency(totalVal);
                    $('.summary-container .sum .value').html(totalVal);
                }
            }
        },

        calculateShipping: function () {
            var td;
            var shippingPrice;
            var orderPrice;
            var totalVal;

            td = this.options.ordersumfield;
            shippingPrice = this.options.shippingValue['s' + this.options.shipping_id];
            this.checkSeparators($('.product-only .sum').html());
            orderPrice = parseFloat(this.parent.toPrimaryValue($('.product-only .sum').html()));
            totalVal = shippingPrice + orderPrice;
            totalVal = this.format_currency(totalVal);

            this.checkSeparators($('.recount .sum .color').html());
            $('.sum .value').html(totalVal);

            if (
                Shop.exist(td) &&
                Shop.exist(this.options.shippingValue) &&
                Shop.exist(this.options.sumNoShipping) &&
                this.options.shipping_id > 0
            ) {
                var adds = this.shipping_additional(this.options.shipping_id, this.options.payment_id);
                var sv = this.options.shippingValue['s' + this.options.shipping_id];
                if (adds != null) {
                    sv += adds['v'];
                }

                var s = this.options.sumNoShipping + sv;
                $(td).html(this.format_currency(s));

                $('.sumhref').each(function () {
                    var url = $(this).attr('data-sum-href').replace('{sum}', s);
                    if (url) {
                        $(this).attr('href', url);
                    }
                });

                if (Shop.exist(this.options.shipping_row)) {
                    this.options.containers.shipping.find(this.options.selectors.deliveryheadvalue).html(this.format_currency(sv));
                }
            }
        },

        shipping_additional: function (sid, pid) {
            if (
                this.options.paymentAdditional &&
                this.options.paymentAdditional['s' + sid] &&
                this.options.paymentAdditional['s' + sid]['p' + pid]
            ) {
                return this.options.paymentAdditional['s' + sid]['p' + pid];
            }
            return null;
        },

        step1_show_delivery: function () {
            var self = this;
            this.step1_hide_payment();
            this.options.containers.step1
                .find(this.options.selectors.deliveryrow)
                .filter(function () {
                    return !$(self).find(self.options.selectors.trradio).is('disabled');
                })
                .removeClass('none');
        },

        step1_show_payment: function () {
            var self = this;
            this.step1_hide_delivery();
            this.options.containers.step1
                .find(this.options.selectors.paymentrow)
                .filter(function () {
                    return !self.find(self.options.selectors.trradio).is('disabled');
                })
                .removeClass('none');
        },

        step1_hide_delivery: function () {
            if (this.options.force_rows == false) return;
            this.options.containers.step1.find(this.options.selectors.deliveryrow).addClass('none');
            this.options.containers.step1.find(this.options.selectors.deliverychangelink).removeClass('none');
        },

        step1_hide_payment: function () {
            if (this.options.force_rows == false) return;
            this.options.containers.step1.find(this.options.selectors.paymentrow).addClass('none');
            this.options.containers.step1.find(this.options.selectors.paymentchangelink).removeClass('none');
        },

        step1: function () {
            if (!Shop.exist(this.options.containers.step1)) return;
            var self = this;
            var $recalc;

            $('.btn-remove-promocode').on('click', function (evt) {
                evt.preventDefault();

                $.ajax({
                    method: 'put',
                    url: '/webapi/front/' + Shop.lang.name + '/basket/' + Shop.values.currency + '/',
                    data: JSON.stringify({
                        promocode: null
                    }),
                    success: function (data, statusText) {
                        if (data && statusText === 'success') {
                            window.location = window.location.href;
                        }
                    }
                });
            });

            $('#promocodeshow')
                .on('change', function (e) {
                    var par = $(this).parents('.promocode'),
                        inp = par.find('.input'),
                        act = par.find('.action');

                    if ($(this).is(':checked')) {
                        inp.show();
                        act.show();
                    } else {
                        inp.hide();
                        act.hide();
                    }
                })
                .trigger('change');

            this.options.country_id = parseInt($('select[name="shipping_country"]').val(), 10);
            $('select[name="shipping_country"]').on('change', function () {
                self.options.country_id = parseInt($(this).val(), 10);
                self.calculate();
            });

            var f = this.options.containers.step1.find(this.options.selectors.deliverychangelink);
            if (Shop.exist(f)) {
                f.removeClass('none');
                if (f.width() > 0) {
                    this.force_rows = true;

                    this.options.containers.step1.find(this.options.selectors.deliverychangelink).each(function () {
                        this._class = self;
                    });

                    this.options.containers.step1.find(this.options.selectors.deliverychangelink).on('click', function (e) {
                        if (e) {
                            e.preventDefault();
                            e.stopPropagation();
                        }

                        $(this).fadeOut('fast');
                        if (this._class.options._folden_delivery == true) {
                            this._class.step1_hide_payment();
                            this._class.options._folden_payment = true;
                            this._class.step1_show_delivery();
                        } else this._class.step1_hide_delivery();
                        this._class.options._folden_delivery = !this._class.options._folden_delivery;
                    });

                    this.options.containers.step1.find(this.options.selectors.paymentchangelink).each(function () {
                        this._class = self;
                    });

                    this.options.containers.step1.find(this.options.selectors.paymentchangelink).on('click', function (e) {
                        if (e) {
                            e.preventDefault();
                            e.stopPropagation();
                        }

                        this.blur();
                        if (this._class.options._folden_payment == true) {
                            this._class.step1_show_payment();
                            this._class.step1_hide_delivery();
                            this._class.options._folden_delivery = true;
                        } else this._class.step1_hide_payment();
                        this._class.options._folden_payment = !this._class.options._folden_payment;
                    });

                    this.step1_hide_delivery();
                    this.step1_hide_payment();
                    this.options._folden_payment = true;
                    this.options._folden_delivery = true;
                }
            }

            this.options.containers.step1.find(this.options.selectors.paymentradios).each(function () {
                this._class = self;

                $(this).on('change', function (e) {
                    this._class.options.payment_id = parseInt($(this).attr('value'));
                    this._class.options.containers.step1.find(this._class.options.selectors.paymentrow).removeClass('selected');

                    var tr = $(this).parent();
                    while (tr.prop('tagName') != 'DIV') tr = tr.parent();

                    tr.addClass('selected');

                    this._class.options.containers.step1
                        .find(this._class.options.selectors.paymentheadlabel)
                        .html(tr.find(this._class.options.selectors.paymentrlabel).text());
                    this._class.calculate();
                    this._class.step1_hide_payment();
                    this._class.options._folden_payment = true;

                    setTimeout(
                        function () {
                            if (this._class.options.shippingHidden) {
                                $(this._class.options.selectors.deliverylabel).text(Shop.lang.basket.shipping_payment_from + ':');
                            } else if (tr.find('.value').text().length > 0) {
                                $(this._class.options.selectors.deliverylabel).text(Shop.lang.basket.shipping_payment_cost + ':');
                            } else {
                                $(this._class.options.selectors.deliverylabel).text(Shop.lang.basket.shipping_payment + ':');
                            }
                        }.bind(this),
                        100
                    );
                });

                if (this.checked == true) $(this).trigger('change');
            });

            this.options.containers.step1.find(this.options.selectors.deliveryradios).each(function () {
                this._class = self;

                $(this).on('change', function (e) {
                    const shippingId = parseInt(this.value, 10);
                    this._class.options.shipping_id = shippingId;

                    var tr = $(this).parent();
                    while (tr.prop('tagName') != 'DIV') tr = tr.parent();

                    tr.parent().children(this._class.options.selectors.deliveryrow).removeClass('selected');
                    tr.addClass('selected');
                    this._class.options.shipping_row = tr;

                    setTimeout(
                        function () {
                            if (this._class.options.shippingHidden) {
                                $(this._class.options.selectors.deliverylabel).text(Shop.lang.basket.shipping_payment_from + ':');
                            } else if ($('.payment.selected .value').text().length > 0) {
                                $(this._class.options.selectors.deliverylabel).text(Shop.lang.basket.shipping_payment_cost + ':');
                            } else {
                                $(this._class.options.selectors.deliverylabel).text(Shop.lang.basket.shipping_payment + ':');
                            }
                        }.bind(this),
                        100
                    );

                    var trs = this._class.options.containers.step1.find(this._class.options.selectors.paymentrow);
                    var inputs = [];
                    var first = false;
                    var that = this;
                    trs.each(function () {
                        this._class = self;

                        var paypalId = $('button[value="payPalExpressCheckout"]').data('paymentId');
                        var payPalButton = $('#payment_' + paypalId);
                        var payPalB = $('#paypal-button');
                        var input = $(this).find(this._class.options.selectors.trradio);
                        if (!Shop.exist(input)) return;
                        var paymentId = parseInt(input.attr('value'));
                        var a = self.options.shipping2Payment['s' + shippingId];

                        var aContain = function () {
                            for (var val in a) {
                                if (a[val] != paymentId) continue;
                                else {
                                    return true;
                                }
                            }
                        };

                        var aContain = aContain();
                        if (aContain === undefined) aContain = false;

                        if (payPalButton.length > 0) {
                            payPalB.removeClass('none');
                        } else if (payPalB.length > 0) {
                            payPalB.addClass('none');
                        }

                        if (Shop.exist(a) && aContain) {
                            inputs.push(input);
                            input.attr('disabled', false);
                            input.attr('readonly', false);
                            $(this).removeClass('none');

                            if (payPalButton.length > 0 && payPalB.length > 0) {
                                payPalB.removeClass('none');
                            }

                            if (false == first) {
                                $(this).addClass('first').removeClass('none');
                                first = true;
                            } else {
                                $(this).removeClass('first');
                            }
                            var adds = self.shipping_additional(shippingId, paymentId);
                            if (adds !== null) {
                                $(this)
                                    .find('.value')
                                    .html('+' + adds['t']);
                                if (typeof adds['p'] === 'undefined') {
                                    $(this).find('.additional_cost_percent').html('');
                                } else {
                                    $(this)
                                        .find('.additional_cost_percent')
                                        .html('(' + adds['p'] + ')');
                                }
                            } else {
                                $(this).find('.value').html('');
                                $(this).find('.additional_cost_percent').html('');
                            }
                        } else {
                            input.attr('disabled', true);
                            input.attr('readonly', true);
                            input.prop('checked', false);
                            $(this).addClass('none').removeClass('first');

                            if (payPalButton.length > 0 && payPalB.length > 0) {
                                payPalB.addClass('none');
                            }
                        }

                        if (Shop.values.ShippingHidden && payPalB.length > 0) {
                            var showPayPal = false;

                            self.parent.forEachIn(self.options.shipping2Payment, function (arr) {
                                if (arr.indexOf(paypalId) >= 0) {
                                    showPayPal = true;
                                }
                            });

                            if (showPayPal === true) {
                                payPalB.removeClass('none');
                            }
                        }
                    });

                    var inputsLength = inputs.length;
                    if (inputsLength > 0) {
                        var checked = false;
                        for (var x = 0; x < inputsLength; ++x) if (inputs[x].is(':checked') == true) checked = true;
                        if (checked == false) {
                            inputs[0].prop('checked', true);
                            inputs[0].trigger('change');
                        }
                    }

                    this._class.calculate();
                    this._class.step1_hide_delivery();
                    this._class.options._folden_delivery = true;
                    updateDeliveryTimes(this._class.options.deliveryTimes, shippingId);
                });

                if (this.checked == true) {
                    $(this).trigger('change');
                }
            });

            $('.quantity input[type="text"], .quantity input[type="number"]').on('keydown', function () {
                if (!$('#recalc').is(':visible')) {
                    $('#recalc').trigger('pay.change');
                }
            });

            $('.quantity input[type="number"]').on('change', function () {
                if (!$('#recalc').is(':visible')) {
                    $('#recalc').trigger('pay.change');
                }
            });

            if (Shop.exist(this.options.country2Shipping)) {
                var tr = this.options.containers.step1.find(this.options.selectors.countrytr);
                if (Shop.exist(tr)) {
                    var sel = tr.find(this.options.selectors.trcountryselect);
                    this._class = sel;

                    if (sel.children().length > 0) {
                        if (sel.children().length > 1) {
                            tr.removeClass('none');
                        }

                        sel._class = this;
                        sel._tr = tr;

                        sel.on('change', function (e) {
                            var cs = sel._class.options.country2Shipping[$(this).val()];
                            var first = null;
                            var unchecked = false;

                            sel._tr
                                .parent()
                                .find(sel._class.options.selectors.deliveryradios)
                                .each(
                                    function (arg) {
                                        var i = $(this);

                                        var disCheck = function () {
                                            for (var val in arg) {
                                                if (arg[val] != i.val()) {
                                                    $(this).parents('.delivery').addClass('none');
                                                    continue;
                                                } else {
                                                    $(this).parents('.delivery').removeClass('none');
                                                    return true;
                                                }
                                            }
                                        }.bind(this);

                                        this.disabled = !disCheck();

                                        if (true == this.disabled) {
                                            if (true == this.checked) {
                                                this.checked = false;
                                                unchecked = true;
                                            }
                                        } else if (null == first) {
                                            first = this;
                                        }
                                    },
                                    [cs]
                                );
                            if (first && true == unchecked) {
                                first.checked = true;
                                $(first).trigger('change');
                            }
                            this.blur();
                        });

                        sel.trigger('change');
                    }
                }
            }

            $recalc = $('#recalc');
            $recalc.on('pay.change', function () {
                $(this).show();
            });

            this.step1_hide_delivery();
            this.step1_hide_payment();

            if ($('.pp-pick-up-points').length) {
                this.pocztaPolskaBasketHandler.pickUpPointsStepDelivery($('#box_basketlist form'));
            }

            if (
                Shop.values.partnerEE ||
                Shop.values.clientEE ||
                Features.isEnabled('google_analytics_v4') ||
                Features.isEnabled('facebook_business_extension')
            ) {
                this.enhancedEcommerce();
            }
        },

        step2: function () {
            if (!Shop.exist(this.options.containers.step2)) return;
            var shopClass = this.parent;

            var f = function myself() {
                myself._form = this[0];
                myself._sub = this[1];
                var that = this[2];

                var trs = myself._form.find(that.options.selectors.trdifferentaddress);
                var i = myself._form.find(that.options.selectors.differentaddress);

                if (Shop.exist(i)) {
                    if (i.is(':checked')) trs.removeClass('none');
                    else {
                        trs.addClass('none');
                    }
                } else {
                    i.prop('checked', false);
                }

                var r = $(myself._form).find(that.options.selectors.companyaddress);
                var countrySelect = that.options.containers.step2.find(that.options.selectors.countryselect);
                var c = 'PL' == countrySelect.val().replace(/[^A-Z]/g, '') || '179' == countrySelect.val();

                if (Shop.exist(r)) {
                    myself._sub(myself._form.find(that.options.selectors.formcompanyname), r.is(':checked'));
                    myself._sub(myself._form.find(that.options.selectors.formtaxid), r.is(':checked'));
                    myself._sub(myself._form.find(that.options.selectors.formcompanynameError), r.is(':checked'));
                    myself._sub(myself._form.find(that.options.selectors.formtaxidError), r.is(':checked'));
                    myself._sub(myself._form.find(that.options.selectors.formpesel), c && !r.is(':checked'));
                    myself._sub(myself._form.find(that.options.selectors.formcompanyname2), i.is(':checked')); //another address
                    //myself._sub(myself._form.getElement('input[name=coname2]'), r.checked && i.checked); // another address & company
                    myself._sub(myself._form.find(that.options.selectors.formtaxid2), i.is(':checked')); //another address
                    //myself._sub(myself._form.getElement('input[name=nip2]'), r.checked && i.checked); // another address & company
                }
            }.bind([
                this.options.containers.step2.find('form'),
                function (el, show) {
                    if (Shop.exist(el)) {
                        var tr = $(el).closest('tr');
                        if (tr) {
                            if (show == true) {
                                tr.removeClass('none');
                                tr.find('input, select').removeClass('none').attr('disabled', false);
                            } else {
                                tr.addClass('none');
                                tr.find('input, select').addClass('none').attr('disabled', true);
                            }
                        }
                    }
                },
                this
            ]);

            f();

            this.options.containers.step2.find(this.options.selectors.countryselect).each(
                function (that) {
                    that._class = that;
                    $(this).on('change', [that], function (e) {
                        var that = e.data[0];
                        var v = parseInt($(this).val(), 10);
                        var chkbx = that._class.options.containers.step2.find(that._class.options.selectors.differentaddress);
                        if (!Shop.exist(chkbx)) return;

                        /* required varies by country */
                        var fields = $('.coname em.color, .nip em.color');

                        if ($.inArray(v, that._class.options.country2Shipping) < 0) {
                            chkbx.attr('data-can-change', false);

                            if (chkbx.is(':checked') == false) {
                                chkbx.prop('checked', true);
                                chkbx.trigger('change');
                            }
                            fields.hide();
                        } else {
                            chkbx.attr('data-can-change', true);
                            fields.show();
                        }
                    });
                    $(this).trigger('change');
                },
                [this]
            );

            this.options.containers.step2.find(this.options.selectors.addresstyperadios).each(function () {
                this._change = f;
                $(this).on('change', function () {
                    this._change();
                });
            });

            this.options.containers.step2.find(this.options.selectors.differentaddress).each(function () {
                this._change = f;

                $(this).on('change', function () {
                    if ($(this).attr('data-can-change') == 'false' && $(this).is(':checked') == false) {
                        (function () {
                            shopClass.alert(Shop.lang.basket.shipping_different_country);
                            $(this).prop('checked', true);
                            this._change();
                        }.apply(this));
                    } else {
                        this._change();
                    }
                });
                this._change();
            });

            this.options.containers.step2.find(this.options.selectors.addressselectsubmit).addClass('none');

            this.options.containers.step2.find(this.options.selectors.addressselect).each(
                function (that) {
                    this._class = that;
                    var self = this;

                    $(this).on('change', function (e) {
                        var val = parseInt($(this).val());
                        if (val > 0) {
                            var urlS = this._class.options.getaddressurl.replace('{id}', val);
                            var req = $.ajax({
                                url: shopClass.url(urlS),
                                async: false,
                                cache: false,
                                method: 'get'
                            })
                                .error(function (xhr) {
                                    if (200 != this.status) {
                                        shopClass.alert('todo lang');
                                    }
                                    shopClass.alert(Shop.lang.basket.address_request_error);
                                })
                                .success(function (json) {
                                    if (Shop.exist(json) && Shop.exist(json.name)) {
                                        var n = $(self).attr('name').match(/2$/) ? '2' : '';

                                        for (var k in json) {
                                            var selector = self._class.options.selectors.addressinput.replace('{name}', k + n);
                                            self._class.options.containers.step2.find(selector).val(json[k]).trigger('change');
                                        }
                                        self._class.options.containers.step2.find(self._class.options.selectors.countryselect).trigger('change');

                                        if (n == '') {
                                            if (json.nip == '' && json.coname == '') {
                                                $(self._class.options.selectors.personaladdress).prop('checked', true).trigger('change');
                                            } else {
                                                $(self._class.options.selectors.companyaddress).prop('checked', true).trigger('change');
                                            }
                                        }

                                        if (n == '2') {
                                            var select = self._class.options.containers.step2.find(
                                                self._class.options.selectors.addressinput.replace('{name}', 'country2')
                                            );
                                            if (select.val() != json.country) {
                                                select
                                                    .find('option')
                                                    .filter(function () {
                                                        return $(this).val() == '';
                                                    })
                                                    .remove();

                                                $('<option />').appendTo(select);
                                                select.val('');
                                            }
                                        }
                                    } else {
                                        shopClass.alert(Shop.lang.basket.address_request_error);
                                    }
                                });
                        } else {
                            var keys = ['name', 'surname', 'phone', 'coname', 'nip', 'street', 'zip', 'city', 'other_address'];
                            var n = $(self).attr('name').match(/2$/) ? '2' : '';
                            var keysLength = keys.length;

                            for (var x = 0; x < keysLength; ++x) {
                                var selector = self._class.options.selectors.addressinput.replace('{name}', keys[x] + n);
                                self._class.options.containers.step2.find(selector).val('');
                            }
                            if (n == '2') {
                                var select = self._class.options.containers.step2.find(
                                    this._class.options.selectors.addressinput.replace('{name}', 'country2')
                                );
                                select.find('option[value=]').remove();
                            }
                        }
                    });
                },
                [this]
            );

            if (this.options.reverseTable) {
                this.reverseAddress($(this.options.selectors.addressfieldscontainer), '');
                this.reverseAddress($('table.address-different'), '2');
            }

            if (
                Shop.values.partnerEE ||
                Shop.values.clientEE ||
                Features.isEnabled('google_analytics_v4') ||
                Features.isEnabled('facebook_business_extension')
            ) {
                this.eeStep2();
            }
        },

        reverseAddress: function ($container, label) {
            var $country;
            var $state;
            var $city;

            $country = $container.find('tr.country' + label);
            $state = $container.find('tr.state' + label);
            $city = $container.find('tr.city' + label);

            $country.insertBefore($container.find('tr.other_address' + label));
            $state.insertAfter($country);
            $city.insertAfter($state);
        },

        stepshipping: function () {
            var self = this;

            this.options.containers.shipping.find(this.options.selectors.paymentradios).each(function () {
                this._class = self;

                $(this).on('change', function (e) {
                    this._class.options.payment_id = parseInt($(this).attr('value'));
                    this._class.options.containers.shipping.find(this._class.options.selectors.paymentrow).removeClass('selected');

                    var tr = $(this).parent();
                    while (tr.prop('tagName') != 'DIV') tr = tr.parent();

                    tr.addClass('selected');

                    this._class.options.containers.shipping
                        .find(this._class.options.selectors.paymentheadlabel)
                        .html(tr.find(this._class.options.selectors.paymentrlabel).text());
                    this._class.calculateShipping();
                    //this._class.step1_hide_payment();
                    this._class.options._folden_payment = true;

                    setTimeout(
                        function () {
                            if (tr.find('.value').text().length > 0) {
                                $(this._class.options.selectors.deliverylabel).text(Shop.lang.basket.shipping_payment_cost + ':');
                            } else {
                                $(this._class.options.selectors.deliverylabel).text(Shop.lang.basket.shipping_payment + ':');
                            }
                        }.bind(this),
                        100
                    );
                });

                if (this.checked == true) $(this).trigger('change');
            });

            this.options.containers.shipping.find(this.options.selectors.deliveryradios).each(function () {
                this._class = self;

                $(this).on('change', function (e) {
                    this._class.options.shipping_id = parseInt($(this).attr('value'));

                    var tr = $(this).parent();
                    while (tr.prop('tagName') != 'DIV') tr = tr.parent();

                    tr.parent().children(this._class.options.selectors.deliveryrow).removeClass('selected');
                    tr.addClass('selected');
                    this._class.options.shipping_row = tr;

                    setTimeout(
                        function () {
                            if ($('.payment.selected .value').text().length > 0) {
                                $(this._class.options.selectors.deliverylabel).text(Shop.lang.basket.shipping_payment_cost + ':');
                            } else {
                                $(this._class.options.selectors.deliverylabel).text(Shop.lang.basket.shipping_payment + ':');
                            }
                        }.bind(this),
                        100
                    );

                    var trs = this._class.options.containers.shipping.find(this._class.options.selectors.paymentrow);
                    var inputs = [];
                    var first = false;
                    var that = this;
                    trs.each(function () {
                        this._class = self;

                        var input = $(this).find(this._class.options.selectors.trradio);
                        if (!Shop.exist(input)) return;
                        var shippingId = $(that).attr('value');
                        var paymentId = parseInt(input.attr('value'));
                        var a = self.options.shipping2Payment['s' + shippingId];

                        var aContain = function () {
                            for (var val in a) {
                                if (a[val] != paymentId) continue;
                                else {
                                    return true;
                                }
                            }
                        };

                        var aContain = aContain();
                        if (aContain === undefined) aContain = false;

                        if (Shop.exist(a) && aContain) {
                            inputs.push(input);
                            input.attr('disabled', false);
                            input.attr('readonly', false);
                            $(this).removeClass('none');

                            if (false == first) {
                                $(this).addClass('first').removeClass('none');
                                first = true;
                            } else {
                                $(this).removeClass('first');
                            }
                            var adds = self.shipping_additional(shippingId, paymentId);

                            if (adds !== null) {
                                $(this)
                                    .find('.value')
                                    .html('+' + adds['t']);
                                if (typeof adds['p'] === 'undefined') {
                                    $(this).find('.additional_cost_percent').html('');
                                } else {
                                    $(this)
                                        .find('.additional_cost_percent')
                                        .html('(' + adds['p'] + ')');
                                }
                            } else {
                                $(this).find('.value').html('');
                                $(this).find('.additional_cost_percent').html('');
                            }
                        } else {
                            input.attr('disabled', true);
                            input.attr('readonly', true);
                            input.prop('checked', false);
                            $(this).addClass('none').removeClass('first');
                        }
                    });

                    var inputsLength = inputs.length;
                    if (inputsLength > 0) {
                        var checked = false;
                        for (var x = 0; x < inputsLength; ++x) if (inputs[x].is(':checked') == true) checked = true;
                        if (checked == false) {
                            inputs[0].prop('checked', true);
                            inputs[0].trigger('change');
                        }
                    }

                    this._class.calculateShipping();
                    //this._class.step1_hide_delivery();
                    this._class.options._folden_delivery = true;
                });

                if (this.checked == true) {
                    $(this).trigger('change');
                }
            });

            if (
                Shop.values.partnerEE ||
                Shop.values.clientEE ||
                Features.isEnabled('google_analytics_v4') ||
                Features.isEnabled('facebook_business_extension')
            ) {
                this.eeStepE();
            }

            if ($('.pp-pick-up-points').length) {
                this.pocztaPolskaBasketHandler.pickUpPointsStepDelivery($('form[action*="/pl/basket/step3"]'));
            }
        },

        step3: function () {
            if (
                Shop.values.partnerEE ||
                Shop.values.clientEE ||
                Features.isEnabled('google_analytics_v4') ||
                Features.isEnabled('facebook_business_extension')
            ) {
                this.eeStep3();
            }

            if ($('.pp-pick-up-points').length) {
                this.pocztaPolskaBasketHandler.pickUpPointsStep3();
            }
        },

        stepdone: function () {
            if (Shop.shippingDone) {
                customerPrivacy.onPlatformConsentGranted(() => {
                    if (Shop.values.partnerEE) {
                        shopLayer.push({
                            event: 'transaction',
                            ecommerce: {
                                currencyCode: Shop.values.currency,
                                purchase: {
                                    actionField: Shop.shippingDone.data || {},
                                    products: Shop.shippingDone.products || []
                                }
                            }
                        });
                    }
                });

                customerPrivacy.onAnalyticsConsentGranted(() => {
                    if (Shop.values.clientEE) {
                        if (window.ga) {
                            Shop.shippingDone.clientProducts = Shop.shippingDone.clientProducts || [];
                            Shop.shippingDone.clientProducts.forEach(function (product) {
                                ga('client.ec:addProduct', product);
                            });

                            Shop.shippingDone.dataClient = Shop.shippingDone.dataClient || {};
                            ga('client.ec:setAction', 'purchase', Shop.shippingDone.dataClient);
                            ga('client.send', 'pageview');
                        }
                    } else {
                        $on('ga:ready', () => {
                            if (Shop.values.clientEE) {
                                if (window.ga) {
                                    Shop.shippingDone.clientProducts = Shop.shippingDone.clientProducts || [];
                                    Shop.shippingDone.clientProducts.forEach(function (product) {
                                        ga('client.ec:addProduct', product);
                                    });

                                    Shop.shippingDone.dataClient = Shop.shippingDone.dataClient || {};
                                    ga('client.ec:setAction', 'purchase', Shop.shippingDone.dataClient);
                                    ga('client.send', 'pageview');
                                }
                            }
                        });
                    }
                });
            }

            customerPrivacy.onPlatformConsentGranted(() => {
                if (Shop.values.partnerEE) {
                    shopLayer.push({
                        event: 'checkout',
                        ecommerce: {
                            currencyCode: Shop.values.currency,
                            checkout: {
                                actionField: {
                                    step: Shop.values.ShippingHidden ? 5 : 4
                                },
                                products: []
                            }
                        }
                    });
                }
            });

            customerPrivacy.onAnalyticsConsentGranted(() => {
                if (Shop.values.clientEE) {
                    if (window.ga) {
                        ga('client.ec:setAction', 'checkout', {
                            step: Shop.values.ShippingHidden ? 5 : 4
                        });

                        ga('client.send', 'pageview');
                    }
                } else {
                    $on('ga:ready', () => {
                        if (Shop.values.clientEE) {
                            if (window.ga) {
                                ga('client.ec:setAction', 'checkout', {
                                    step: Shop.values.ShippingHidden ? 5 : 4
                                });

                                ga('client.send', 'pageview');
                            }
                        }
                    });
                }
            });

            if (Features.isEnabled('google_analytics_v4') || Features.isEnabled('facebook_business_extension')) {
                const purchasedProducts = Shop.shippingDone.clientProducts ?? Shop.shippingDone.products;
                const purchasedAnalyticProducts = purchasedProducts.map((product) => new AnalyticProduct(product));
                const eventName = PURCHASED;
                const eventOptions = {
                    currency: Shop.values.currency,
                    orderSummary: Shop.shippingDone.dataClient ?? Shop.shippingDone.data,
                    products: purchasedAnalyticProducts
                };

                $emit(eventName, eventOptions);
            }
        },

        enhancedEcommerce: function () {
            var self;
            var $form;
            var $products;
            var quantities;

            quantities = [];
            $products = $('.productlist tr[data-product-id]');
            $products.each(function () {
                quantities.push(parseFloat($(this).find('.quantity input').val()));
            });

            self = this;
            $form = $('#box_basketlist form');

            if ($form.length > 0) {
                $form.find('[type="submit"]').on('click', function (ev) {
                    $('<input />', {
                        type: 'hidden',
                        name: $(this).attr('name'),
                        value: $(this).val()
                    }).appendTo($(this).parent());

                    this.removeAttribute('name');
                    this.removeAttribute('value');
                });

                $form.on('submit', function (ev) {
                    var newValues;
                    var changed;
                    var options;
                    ev.preventDefault();

                    newValues = [];
                    $products.each(function () {
                        newValues.push(parseFloat($(this).find('.quantity input').val()));
                    });

                    changed = [];
                    quantities.forEach(function (el, index) {
                        if (el != newValues[index]) {
                            changed.push(index);
                        }
                    });

                    if (changed.length > 0) {
                        self.updateEE(changed, $products, newValues, quantities);

                        quantities = [];
                        $products.each(function () {
                            quantities.push(parseFloat($(this).find('.quantity input').val()));
                        });
                    }

                    if (!Shop.values.ShippingHidden) {
                        const shippingName = $('input[name="shipping_id"]:checked').parent().next().text().trim();

                        const paymentName = $('input[name="payment_id"]:checked').parent().next().text().trim();

                        options = `${shippingName} ${paymentName}`;
                        options = options.replace(/\s+/g, ' ');

                        customerPrivacy.onPlatformConsentGranted(() => {
                            if (Shop.values.partnerEE) {
                                shopLayer.push({
                                    event: 'checkoutOption',
                                    ecommerce: {
                                        currencyCode: Shop.values.currency,
                                        checkout_option: {
                                            actionField: { step: 1, option: options }
                                        }
                                    }
                                });
                            }
                        });

                        customerPrivacy.onAnalyticsConsentGranted(() => {
                            if (Shop.values.clientEE) {
                                if (window.ga) {
                                    ga('client.ec:setAction', 'checkout_option', {
                                        step: 1,
                                        option: options
                                    });
                                    ga('client.send', 'event', 'Checkout', 'Option');
                                }
                            }
                        });

                        if (Features.isEnabled('google_analytics_v4') || Features.isEnabled('facebook_business_extension')) {
                            $emit(BEGAN_CHECKOUT);

                            $emit(CHECKOUT_OPTIONS_SUBMITED, {
                                shippingName,
                                paymentName,
                                currency: Shop.values.currency
                            });
                        }
                    }

                    this.submit();
                });
            }

            this.eeStep1();
        },

        updateEE: function (changed, $products, quantities, localQuantities) {
            var self;

            self = this;
            changed.forEach(function (el) {
                var product;
                var clientProduct;
                var $container;
                var quantity;

                $container = $products.filter(':eq(' + el + ')');
                if ($container.length > 0) {
                    product = {
                        id: Shop.values.licenseId + '_' + $container.data('productId'),
                        name: $container.find('.name a').text().trim(),
                        price: $container
                            .find('.price:first')
                            .text()
                            .replace(/([^\d,\.])+/g, ''),
                        brand: $container.data('producer') || '',
                        category: $container.data('category') || '',
                        variant: $container.find('.variant').text(),
                        quantity: $container.find('.quantity input').val(),
                        position: $container.index()
                    };

                    clientProduct = {
                        id: $container.data('productId'),
                        name: $container.find('.name a').text().trim(),
                        price: $container
                            .find('.price:first')
                            .text()
                            .replace(/([^\d,\.])+/g, ''),
                        brand: $container.data('producer') || '',
                        category: $container.data('category') || '',
                        variant: $container.find('.variant').text(),
                        quantity: $container.find('.quantity input').val(),
                        position: $container.index()
                    };

                    if (quantities[el] > localQuantities[el]) {
                        quantity = quantities[el] - localQuantities[el];

                        product.quantity = quantity;
                        clientProduct.quantity = quantity;

                        customerPrivacy.onPlatformConsentGranted(() => {
                            if (Shop.values.partnerEE) {
                                shopLayer.push({
                                    event: 'addToCart',
                                    ecommerce: {
                                        currencyCode: Shop.values.currency,
                                        add: {
                                            products: [product],
                                            actionField: {
                                                list: 'checkout'
                                            }
                                        }
                                    }
                                });
                            }
                        });

                        customerPrivacy.onAnalyticsConsentGranted(() => {
                            if (Shop.values.clientEE) {
                                if (window.ga) {
                                    ga('client.ec:addProduct', clientProduct);
                                    ga('client.ec:setAction', 'add', { list: 'checkout' });
                                    ga('client.send', 'event', 'UX', 'click', 'add to cart');
                                }
                            }
                        });

                        if (Features.isEnabled('google_analytics_v4') || Features.isEnabled('facebook_business_extension')) {
                            const eventName = PRODUCT_ADDED_TO_CART;
                            const eventOptions = new AnalyticProduct({
                                ...clientProduct,
                                currency: Shop.values.currency,
                                listName: 'checkout'
                            });

                            $emit(eventName, eventOptions);
                        }
                    } else {
                        quantity = localQuantities[el] - quantities[el];

                        product.quantity = quantity;
                        clientProduct.quantity = quantity;

                        customerPrivacy.onPlatformConsentGranted(() => {
                            if (Shop.values.partnerEE) {
                                shopLayer.push({
                                    event: 'removeFromCart',
                                    ecommerce: {
                                        currencyCode: Shop.values.currency,
                                        remove: {
                                            products: [product]
                                        }
                                    }
                                });
                            }
                        });

                        customerPrivacy.onAnalyticsConsentGranted(() => {
                            if (Shop.values.clientEE) {
                                if (window.ga) {
                                    ga('client.ec:addProduct', clientProduct);
                                    ga('client.ec:setAction', 'remove');
                                    ga('client.send', 'event', 'UX', 'click', 'remove form cart');
                                }
                            }
                        });

                        if (Features.isEnabled('google_analytics_v4') || Features.isEnabled('facebook_business_extension')) {
                            const eventName = PRODUCT_REMOVED_FROM_CART;
                            const eventOptions = new AnalyticProduct({
                                ...clientProduct,
                                currency: Shop.values.currency,
                                listName: 'checkout'
                            });

                            $emit(eventName, eventOptions);
                        }
                    }
                }
            });
        },

        eeStep1: function () {
            var products;
            var clientProducts;
            var options;

            products = [];
            clientProducts = [];
            $('.productlist tr[data-product-id]').each(function () {
                products.push({
                    id: Shop.values.licenseId + '_' + $(this).data('productId'),
                    name: $(this).find('.name a').text().trim(),
                    price: $(this)
                        .find('.price:first')
                        .text()
                        .replace(/([^\d,\.])+/g, ''),
                    brand: $(this).data('producer') || '',
                    category: $(this).data('category') || '',
                    position: $(this).index(),
                    variant: $(this).find('.variant').text()
                });

                clientProducts.push({
                    id: $(this).data('productId'),
                    name: $(this).find('.name a').text().trim(),
                    price: $(this)
                        .find('.price:first')
                        .text()
                        .replace(/([^\d,\.])+/g, ''),
                    brand: $(this).data('producer') || '',
                    category: $(this).data('category') || '',
                    position: $(this).index(),
                    variant: $(this).find('.variant').text()
                });
            });

            if (products.length > 0) {
                customerPrivacy.onPlatformConsentGranted(() => {
                    if (Shop.values.partnerEE) {
                        shopLayer.push({
                            event: 'checkout',
                            ecommerce: {
                                currencyCode: Shop.values.currency,
                                checkout: {
                                    actionField: {
                                        step: 1
                                    },
                                    products: products
                                }
                            }
                        });
                    }
                });

                customerPrivacy.onAnalyticsConsentGranted(() => {
                    if (Shop.values.clientEE) {
                        if (window.ga) {
                            clientProducts.forEach(function (product) {
                                ga('client.ec:addProduct', product);
                            });

                            ga('client.ec:setAction', 'checkout', {
                                step: 1
                            });

                            ga('client.send', 'pageview');
                        }
                    }
                });

                if (Features.isEnabled('google_analytics_v4') || Features.isEnabled('facebook_business_extension')) {
                    const $couponInput = $('input[type="hidden"][name="promocode"]');
                    const coupon = $couponInput.val();

                    const displayedProducts = clientProducts.map((product) => new AnalyticProduct(product));
                    const eventName = CART_DISPLAYED;
                    const eventOptions = {
                        products: displayedProducts,
                        currency: Shop.values.currency,
                        coupon
                    };

                    $emit(eventName, eventOptions);
                }
            }
        },

        eeStep2: function () {
            customerPrivacy.onPlatformConsentGranted(() => {
                if (Shop.values.partnerEE) {
                    shopLayer.push({
                        event: 'checkout',
                        ecommerce: {
                            currencyCode: Shop.values.currency,
                            checkout: {
                                actionField: {
                                    step: 2
                                }
                            }
                        }
                    });
                }
            });

            customerPrivacy.onAnalyticsConsentGranted(() => {
                if (Shop.values.clientEE) {
                    if (window.ga) {
                        ga('client.ec:setAction', 'checkout', {
                            step: 2
                        });

                        ga('client.send', 'pageview');
                    }
                }
            });
        },

        eeStep3: function () {
            customerPrivacy.onPlatformConsentGranted(() => {
                if (Shop.values.partnerEE) {
                    shopLayer.push({
                        event: 'checkout',
                        ecommerce: {
                            currencyCode: Shop.values.currency,
                            checkout: {
                                actionField: {
                                    step: Shop.values.ShippingHidden ? 4 : 3
                                }
                            }
                        }
                    });
                }
            });

            customerPrivacy.onAnalyticsConsentGranted(() => {
                if (Shop.values.clientEE) {
                    if (window.ga) {
                        ga('client.ec:setAction', 'checkout', {
                            step: Shop.values.ShippingHidden ? 4 : 3
                        });

                        ga('client.send', 'pageview');
                    }
                }
            });
        },

        eeStepE: function () {
            var self;
            var $form;

            customerPrivacy.onPlatformConsentGranted(() => {
                if (Shop.values.partnerEE) {
                    shopLayer.push({
                        event: 'checkout',
                        ecommerce: {
                            currencyCode: Shop.values.currency,
                            checkout: {
                                actionField: {
                                    step: 3
                                }
                            }
                        }
                    });
                }
            });

            customerPrivacy.onAnalyticsConsentGranted(() => {
                if (Shop.values.clientEE) {
                    if (window.ga) {
                        ga('client.ec:setAction', 'checkout', {
                            step: 3
                        });

                        ga('client.send', 'pageview');
                    }
                }
            });

            self = this;
            $form = $('#box_basketshipping form');

            if ($form.length > 0) {
                $form.on('submit', function (ev) {
                    var options;

                    const shippingName = $('input[name="shipping_id"]:checked').parent().next().text().trim();
                    const paymentName = $('input[name="payment_id"]:checked').parent().next().text().trim();

                    options = `${shippingName} ${paymentName}`;
                    options = options.replace(/\s+/g, ' ');

                    customerPrivacy.onPlatformConsentGranted(() => {
                        if (Shop.values.partnerEE) {
                            shopLayer.push({
                                event: 'checkoutOption',
                                ecommerce: {
                                    currencyCode: Shop.values.currency,
                                    checkout_option: {
                                        actionField: {
                                            step: 3,
                                            option: options
                                        }
                                    }
                                }
                            });
                        }
                    });

                    customerPrivacy.onAnalyticsConsentGranted(() => {
                        if (Shop.values.clientEE) {
                            if (window.ga) {
                                ga('client.ec:setAction', 'checkout_option', {
                                    step: 3,
                                    option: options
                                });
                                ga('client.send', 'event', 'Checkout', 'Option');
                            }
                        }
                    });

                    if (Features.isEnabled('google_analytics_v4') || Features.isEnabled('facebook_business_extension')) {
                        const eventName = CHECKOUT_OPTIONS_SUBMITED;
                        const eventOptions = {
                            shippingName,
                            paymentName,
                            currency: Shop.values.currency
                        };

                        $emit(eventName, eventOptions);
                    }
                });
            }
        }
    });
})();
