import { TGoogleAnalyticsV4GtagEvent, TGoogleAnalyticsV4GtagOptions } from './types';

import { AnalyticService } from '@frontstoreRwd/modules/analytics/classes/service';
import { GtagSDK } from './sdk';
import { TServiceName } from '../../types';
import eventsHandlersMapper from './events';

export class GoogleAnalyticV4GtagService extends AnalyticService<GoogleAnalyticV4GtagService> {
    private _dataLayerName = 'dataLayer';
    private _options: TGoogleAnalyticsV4GtagOptions;
    private _gtag: (...args: unknown[]) => void = () => undefined;

    protected _events: TGoogleAnalyticsV4GtagEvent = eventsHandlersMapper;

    constructor(name: TServiceName, options: TGoogleAnalyticsV4GtagOptions) {
        super(name);
        this._options = options;
    }

    public async setup(): Promise<void> {
        const layerName = this._dataLayerName;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window[layerName] = window[layerName] || [];
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        function gtag(...args: unknown[]) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            // eslint-disable-next-line prefer-rest-params
            window[layerName].push(arguments);
        }

        this._gtag = gtag;
        gtag('js', new Date());

        this._options.trackingId.forEach((trackingId) => {
            gtag('config', trackingId);
        });

        await GtagSDK.create(this._options.trackingId[0]);
        this._isSetuped = true;
    }

    public getGtag(): (...args: unknown[]) => void {
        return this._gtag;
    }
}
