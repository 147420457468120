import { AnalyticProduct } from '@frontstoreRwd/modules/analytics/classes/product';
import { TGoogleAnalyticsV4Product } from './types';

export class GoogleAnalyticsV4Map {
    public static toV4Product(product: AnalyticProduct): TGoogleAnalyticsV4Product {
        return {
            item_name: product.name, // Name or ID is required.
            item_id: product.id,
            item_brand: product.brand,
            item_category: product.category,
            item_variant: product.variant,
            item_list_name: product.listName,
            index: product.position,
            price: product.price,
            currency: product.currency
        };
    }
}
