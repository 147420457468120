import {
    ADD_PAYMENT_INFO,
    ADD_SHIPPING_INFO,
    ADD_TO_CART,
    BEGIN_CHECKOUT,
    PURCHASE,
    REMOVE_FROM_CART,
    SELECT_ITEM,
    VIEW_CART,
    VIEW_ITEM,
    VIEW_ITEM_LIST
} from '../event_names';
import {
    BEGAN_CHECKOUT,
    CART_DISPLAYED,
    CHECKOUT_OPTIONS_SUBMITED,
    PRODUCTS_DISPLAYED,
    PRODUCT_ADDED_TO_CART,
    PRODUCT_CLICKED,
    PRODUCT_DISPLAYED,
    PRODUCT_REMOVED_FROM_CART,
    PURCHASED
} from '@frontstoreRwd/events/events_names';

import { AnalyticProduct } from '@frontstoreRwd/modules/analytics/classes/product';
import { GoogleAnalyticV4GTMService } from '.';
import { GoogleAnalyticsV4Map } from '../mapper';
import { TGoogleAnalyticsV4GTMEvent } from './types';

const eventsHandlersMapper: TGoogleAnalyticsV4GTMEvent = {
    [PRODUCT_CLICKED]: (service: GoogleAnalyticV4GTMService, analyticProduct: unknown): void => {
        if (analyticProduct instanceof AnalyticProduct) {
            const dataLayer = service.getLayer();
            const analyticsV4Product = GoogleAnalyticsV4Map.toV4Product(analyticProduct);

            dataLayer.push({ ecommerce: null });
            dataLayer.push({
                event: SELECT_ITEM,
                ecommerce: {
                    items: [analyticsV4Product]
                }
            });
        }
    },

    [PRODUCT_ADDED_TO_CART]: (service: GoogleAnalyticV4GTMService, analyticProduct: unknown): void => {
        if (analyticProduct instanceof AnalyticProduct) {
            const dataLayer = service.getLayer();
            const analyticsV4Product = GoogleAnalyticsV4Map.toV4Product(analyticProduct);

            dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
            dataLayer.push({
                event: ADD_TO_CART,
                ecommerce: {
                    items: [analyticsV4Product],
                    currency: analyticsV4Product.currency,
                    value: analyticsV4Product.price
                }
            });
        }
    },

    [PRODUCT_REMOVED_FROM_CART]: (service: GoogleAnalyticV4GTMService, analyticProduct: unknown): void => {
        if (analyticProduct instanceof AnalyticProduct) {
            const dataLayer = service.getLayer();
            const analyticsV4Product = GoogleAnalyticsV4Map.toV4Product(analyticProduct);

            dataLayer.push({ ecommerce: null });
            dataLayer.push({
                event: REMOVE_FROM_CART,
                ecommerce: {
                    items: [analyticsV4Product],
                    currency: analyticsV4Product.currency,
                    value: analyticsV4Product.price
                }
            });
        }
    },

    [PRODUCT_DISPLAYED]: (service: GoogleAnalyticV4GTMService, analyticProduct: unknown): void => {
        if (analyticProduct instanceof AnalyticProduct) {
            const dataLayer = service.getLayer();
            const analyticsV4Product = GoogleAnalyticsV4Map.toV4Product(analyticProduct);

            dataLayer.push({ ecommerce: null });
            dataLayer.push({
                event: VIEW_ITEM,
                ecommerce: {
                    items: [analyticsV4Product],
                    currency: analyticsV4Product.currency,
                    value: analyticsV4Product.price
                }
            });
        }
    },

    [PRODUCTS_DISPLAYED]: (service: GoogleAnalyticV4GTMService, params: unknown): void => {
        if (typeof params === 'object') {
            const dataLayer = service.getLayer();
            const analyticProductsList = params as {
                products: AnalyticProduct[];
                currency: string;
            };
            const analyticsV4Products = analyticProductsList.products.map((product: AnalyticProduct) =>
                GoogleAnalyticsV4Map.toV4Product(product)
            );

            dataLayer.push({ ecommerce: null });
            dataLayer.push({
                event: VIEW_ITEM_LIST,
                ecommerce: {
                    currency: analyticProductsList.currency,
                    items: analyticsV4Products
                }
            });
        }
    },

    [CART_DISPLAYED]: (service: GoogleAnalyticV4GTMService, params: unknown): void => {
        if (typeof params === 'object') {
            const dataLayer = service.getLayer();
            const analyticProductsList = params as {
                products: AnalyticProduct[];
                currency: string;
                coupon: string | undefined;
            };
            const analyticsV4Products = analyticProductsList.products.map((product: AnalyticProduct) =>
                GoogleAnalyticsV4Map.toV4Product(product)
            );

            dataLayer.push({ ecommerce: null });
            dataLayer.push({
                event: VIEW_CART,
                ecommerce: {
                    currency: analyticProductsList.currency,
                    items: analyticsV4Products,
                    coupon: analyticProductsList.coupon
                }
            });
        }
    },

    [BEGAN_CHECKOUT]: (service: GoogleAnalyticV4GTMService): void => {
        const dataLayer = service.getLayer();
        dataLayer.push({ event: BEGIN_CHECKOUT });
    },

    [CHECKOUT_OPTIONS_SUBMITED]: (service: GoogleAnalyticV4GTMService, params: unknown): void => {
        if (typeof params === 'object') {
            const dataLayer = service.getLayer();
            const checkoutParams = params as {
                shippingName: string;
                paymentName: string;
                currency: string;
            };

            dataLayer.push({ ecommerce: null });
            dataLayer.push({
                event: ADD_PAYMENT_INFO,
                ecommerce: {
                    currency: checkoutParams.currency,
                    payment_type: checkoutParams.paymentName
                }
            });

            dataLayer.push({ ecommerce: null });
            dataLayer.push({
                event: ADD_SHIPPING_INFO,
                ecommerce: {
                    currency: checkoutParams.currency,
                    shipping_type: checkoutParams.shippingName
                }
            });
        }
    },

    [PURCHASED]: (service: GoogleAnalyticV4GTMService, params: unknown): void => {
        if (typeof params === 'object') {
            const dataLayer = service.getLayer();
            const purchaseParams = params as {
                orderSummary: {
                    id: string;
                    revenue: string;
                    shipping: string;
                    tax: string;
                };
                products: AnalyticProduct[];
                currency: string;
            };
            const analyticsV4Products = purchaseParams.products.map((product: AnalyticProduct) => GoogleAnalyticsV4Map.toV4Product(product));

            dataLayer.push({ ecommerce: null });
            dataLayer.push({
                event: PURCHASE,
                ecommerce: {
                    transaction_id: purchaseParams.orderSummary.id,
                    value: purchaseParams.orderSummary.revenue,
                    shipping: purchaseParams.orderSummary.shipping,
                    tax: purchaseParams.orderSummary.tax,
                    currency: purchaseParams.currency,
                    items: analyticsV4Products
                }
            });
        }
    }
};

export default eventsHandlersMapper;
