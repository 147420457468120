export class GtagSDK {
    public static async create(trackingId: string): Promise<void> {
        const id = 'gtag-jssdk';
        if (document.getElementById(id)) {
            return;
        }

        await GtagSDK._injectScript(id, trackingId);
    }

    private static _injectScript(id: string, trackingId: string): Promise<void> {
        return new Promise((resolve) => {
            const firstScriptOnPage = document.getElementsByTagName('script')[0];
            const sdkScript = document.createElement('script');

            sdkScript.id = id;
            sdkScript.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
            sdkScript.async = true;
            sdkScript.onload = () => {
                resolve();
            };

            firstScriptOnPage.parentNode?.insertBefore(sdkScript, firstScriptOnPage);
        });
    }
}
